<template>
    <div class="not-found">
        <h2>{{ $t('dashboard.oops') }}</h2>
        <p>{{ $t('dashboard.return_home') }}</p>
        <router-link to="/">
            <Button :label="$t('footer.home')" />
        </router-link>
    </div>
</template>
<script>
import Button from '@/components/Button'
export default {
    name: 'NotFound',
    components: {
      Button
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/partials/_404";
</style>
